<template>
  <v-container>
  <div>
    <input ref="excel-upload-input" class="excel-upload-input" type="file" accept=".xlsx, .xls" @change="handleClick">
    <div :class="[(excelData.messageFormatInfo=='')?'light-blue--text':'red--text']">{{fileName!='' && excelData.messageFormatInfo==''?'The '+fileName+' file is loaded.':excelData.messageFormatInfo}}</div>
    <div class="drop" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover">
      Drop excel file here or
      <!-- <el-button :loading="loading" style="margin-left:16px;" size="mini" type="primary" @click="handleUpload">
        Browse
      </el-button> -->      
      <!--<v-btn size="mini" type="secundary" @click="configFormat"><v-icon>mdi-cog</v-icon></v-btn>-->
      <v-btn :loading="loading" style="margin-left:16px;" size="mini" type="primary" @click="handleUpload">Abrir</v-btn>
    </div>    
  </div>
  <v-dialog v-model="dialogConfig" persistent width="400">
    <v-card style="height: 100%">
      <v-card-title class="justify-center pa-1">Config section sheet</v-card-title>
      <v-img
        :src="urlImgConfig" 
        height="200px"
        cover       
      ></v-img>
      <v-card-text>        
        <div>
          <h3>In how many columns is the full name?</h3>          
          <v-switch
          v-model="cols"
          hide-details
          true-value="2"
          false-value="1"
          color="orange-darken-3"
          value="orange-darken-3"
          :label="`Columns: ${cols}`"
          @change="showModel"
        ></v-switch>
        </div>
        <div v-if="cols==1">
          <h3>Separator</h3>
          <v-select
          v-model="selectSep"
          :items="[',',';','|']"
          label="Separator"
          density="compact"
        ></v-select>
        </div>
        <div v-if="cols==1">
          <h3>Order</h3>
          <v-select
          v-model="selectOrder"
          :items="[{option:'Last Name and First Name', val:'LF'},{option:'First Name and Last Name', val:'FL'}]"
          item-text="option"
          item-value="val"
          label="Order of Name"
          return-object
          single-line
          @change="showModel"
        ></v-select>
        </div>
      </v-card-text>
      <v-card-actions>        
        <v-btn color="success" @click="applyConfig">Apply<v-icon>mdi-check</v-icon></v-btn>
        <v-btn color="blue-grey lighten-2" outlined class="mr-2" @click="cancelConfig">Cancel<v-icon>mdi-close</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-container>
</template>
<script>
//https://blog.piio.co/posts/how-to-use-images-in-vue-js
import COLS1LF from "../assets/admin/cols1-LF.png"
import COLS1FL from "../assets/admin/cols1-FL.png"
import COLS2 from "../assets/admin/cols2.png"
var XLSX = require('xlsx');
export default {
  props: {
    beforeUpload: Function, // eslint-disable-line
    onSuccess: Function// eslint-disable-line
  },
  data() {
    return {
      dialogConfig: false,
      fileName: '',
      loading: false,      
      urlImgConfig: COLS1LF,
      cols: 1,
      selectSep: ',',
      selectOrder: {option:'Last Name and First Name', val:'LF'},
      config: {
        numCols: 1,
        sep: ',',
        order: {option:'Last Name and First Name', val:'LF'}
      },
      excelData: {
        header: null,
        dataStructure: null,
        messageFormatInfo: null
      }
    }
  },
  methods: {
    generateData({ header, dataStructure, messageFormatInfo }) {
      this.excelData.header = header
      this.excelData.dataStructure = dataStructure
      this.excelData.messageFormatInfo = messageFormatInfo
      this.onSuccess && this.onSuccess(this.excelData)
    },
    handleDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.loading) return
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$message.error('Only support uploading one file!')
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isExcel(rawFile)) {
        this.$message.error('Only supports upload .xlsx, .xls, .csv suffix files')
        return false
      }
      this.upload(rawFile)
      e.stopPropagation()
      e.preventDefault()
    },
    handleDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleUpload() {
      this.$refs['excel-upload-input'].click()
    },
    handleClick(e) {
      const files = e.target.files
      const rawFile = files[0] // only use files[0]
      if (!rawFile) return
      this.upload(rawFile)
    },   
    configFormat(){
      this.dialogConfig=true;
    },
    showModel(){
      this.urlImgConfig=this.cols==2?COLS2:this.cols==1 && this.selectOrder.val=='FL'?COLS1FL:COLS1LF;
    },
    applyConfig(){
      console.log(" Cols: "+this.cols+" Separator: "+this.selectSep+" Order: "+this.selectOrder.val);      
      this.config.numCols=this.cols;
      this.config.sep=this.selectSep;
      this.config.order=this.selectOrder;
      this.dialogConfig=false;      
    },
    cancelConfig(){
      this.cols=this.config.numCols;
      this.selectSep=this.config.sep;
      this.selectOrder=this.config.order;
      this.dialogConfig=false;      
    },
    upload(rawFile) {
      this.fileName=rawFile.name;
      this.$refs['excel-upload-input'].value = null; // fix can't select the same excel
      if (!this.beforeUpload) {
        this.readerData(rawFile);
        return;
      }
      const before = this.beforeUpload(rawFile)
      if (before) {
        this.readerData(rawFile);
      }
    },
    readerData(rawFile) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          const data = e.target.result;
          
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];//Teachers          
          const worksheet_1 = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet_1);
          
          //Nombres de columna que debe tener el excel
          let columnName=["N", "Name", "FirstName", "LastName", "email", "Email"];
          let messageFormatInfo="";
          let dataExcelJSON=[];
          for (let s=0;s<workbook.SheetNames.length;s++){
            const SheetName = workbook.SheetNames[s];
            const worksheet = workbook.Sheets[SheetName];            

            const range = XLSX.utils.decode_range(worksheet['!ref']);
            range.s.c = 0;
            range.e.c = 13;//Largo de la cabecera
            const new_range = XLSX.utils.encode_range(range);
            const results = XLSX.utils.sheet_to_json(worksheet, {header: 0, blankrows:false, defval:"", range: new_range})
            //https://es.stackoverflow.com/questions/538983/angular-manipular-dato
            //https://github.com/SheetJS/sheetjs/issues/1078
            let json_object = JSON.stringify(results);
            // aqui parseamos a json
            const datos = JSON.parse(json_object);
            /*console.log("RESULT---->");
            console.log(datos);
            console.log("ITEMS:");*/
            let breakB=false;
            let principal=this;
            let dataJSON=[];
            let objJSON={};
            let firstRow=false;
            //datos.forEach(function(item) {
            datos.forEach((item, index, arr) => {
              if (!firstRow && principal.nameIsEmpty(item)){//La cabecera esta agregado como un valor {"__EMPTY": "#", "__EMPTY_1":" First Name", "__EMPTY_2":"Last Name","__EMPTY_3": "Email",..}
                //console.log("Esta vacio el primero.");//https://code.tutsplus.com/es/tutorials/how-to-check-if-an-object-is-empty-in-javascript--cms-37053
                firstRow=true;
                return;//Hace de continue en este ambito 
              }
              
              let itemJSON="";
              let keyPast="";
              for (let key in item) {
                if (item[key]=="") continue;  

                let name=key.toString().replace("#","N").replace(/\s+/g, '');
                if (firstRow && key.slice(0,7)=='__EMPTY')
                    name=datos[0][key].toString().replace("#","N").replace(/\s+/g, '');//https://es.stackoverflow.com/questions/150520/crear-un-json-en-javascript
                
                //if (!columnName.includes(name)) throw new Error('No cumple con el formato');
                
                if (!columnName.includes(name)){
                  this.loading = false;
                  messageFormatInfo="No cumple con el formato";
                  console.log("Rompemos");
                  breakB=true;
                  arr.length = index + 1;
                  break;
                }
                
                //if (keyPast!=name){
                  //itemJSON+='"'+name+'":"'+ item[key]+'",';
                let pos=itemJSON.indexOf(name);
                if (pos!=-1){//Ya existe item con este nombre
                  let posS=itemJSON.indexOf('"'+name+'":[');
                  if (posS!=-1){//Entonces tenemos algun elemento q es un array
                    let itemJSONS=itemJSON.substring(0,posS);
                    let posE=itemJSON.substring(posS).indexOf(']');
                    let itemJSONE=itemJSON.substring(posS+posE);
                    let itemContent=itemJSON.substring(posS,posS+posE);
                    let itemValue=itemContent.substring(itemContent.indexOf(":")+1);
                    let itemReplace='"'+name+'":'+itemValue+',"'+item[key]+'"';
                    itemJSON=itemJSONS+itemReplace+itemJSONE;
                  }else{
                    pos--;//Disminuimos en 1 para tener las comillas iniciales mas, solo pasa una vez
                    let itemJSONS=itemJSON.substring(0,pos);
                    let posE=itemJSON.substring(pos).indexOf(',');
                    let itemJSONE=itemJSON.substring(pos+posE);
                    let itemContent=itemJSON.substring(pos,pos+posE);
                    let itemValue=itemContent.substring(itemContent.indexOf(":")+1);
                    let itemReplace ='"'+name+'":['+itemValue+',"'+item[key]+'"]';
                    itemJSON=itemJSONS+itemReplace+itemJSONE;
                  }
                }else itemJSON+='"'+name+'":"'+ item[key]+'",';//No existe un item con este nombre, ingresamos uno nuevo

                keyPast=name;
              }
              if (breakB) return;//Para romper el bucle datos.forEach
              itemJSON='{'+itemJSON.slice(0, -1)+'}';
              let obj=JSON.parse(itemJSON);
              dataJSON.push(obj);            
            });            
            
            if (breakB){ 
              dataExcelJSON=[];
              objJSON.dataJSON=[];
              dataExcelJSON.push(objJSON);
              break;//Para salir totalmente de la funcion
            }
            objJSON.dataJSON=dataJSON;
            dataExcelJSON.push(objJSON);                                  
          }
          //console.log(dataExcelJSON);
          let dataStructure=this.dataStructure(dataExcelJSON);
          console.log("Enviar datos");
          console.log(dataStructure);
          this.generateData({ header, dataStructure, messageFormatInfo });
          this.loading = false;
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    dataStructure(data){
      let dataStructure=[];
      //let grade={"PK":"Pre-School","K":"Kindergarten","1":"1st Grade","2":"2nd Grade","3":"3rd Grade","4":"4th Grade","5":"5th Grade","6":"6th Grade","7":"7th Grade","8":"8th Grade","9":"9th Grade","10":"10th Grade","11":"11th Grade"};
      let grade=["Pre-School","Kindergarten","1st Grade","2nd Grade","3rd Grade","4th Grade","5th Grade","6th Grade","7th Grade","8th Grade","9th Grade","10th Grade","11th Grade"];
      let aGrade=["P|PK","K","1|1st","2|2nd","3|3rd","4|4th","5|5th","6|6th","7|7th","8|8th","9|9th","10|10th","11|11th"];
      for (let t=0;t<data[0].dataJSON.length;t++) {console.log(data[0].dataJSON);
        if (typeof(data[0].dataJSON[t].N)==='undefined') continue;       
        
          
        let formatTeacher='{ \
        "email":"'+data[0].dataJSON[t].Email+'",\
        "name": "'+data[0].dataJSON[t].FirstName+'",\
        "lastName": "'+data[0].dataJSON[t].LastName+'"';          
        formatTeacher+='}';
        dataStructure.push(JSON.parse(formatTeacher));                          
      }
      return dataStructure;    
    },
    getClassRoom(classRoom){
      try{
        let result='[';
        for (let s=0; s<classRoom.length; s++){
          if (this.config.numCols==1){
            let nameStudent=classRoom[s].Name.toString().split(this.config.sep);
            //Por defecto this.config.order.val="LF"            
            let oName=1;
            let oLastName=0;          
            if (this.config.order.val=="FL"){
              oName=0;
              oLastName=1;
            }

            if (nameStudent.length>1)
              result+='{"lastname":"'+nameStudent[oLastName].trim()+'", "name":"'+nameStudent[oName].trim()+'", "cod":"'+classRoom[s].Password+'"},';
            else{            
              result+='{"lastname":"reg no last name", "name":"'+nameStudent[0].trim()+'", "cod":"'+classRoom[s].Password+'"},';  
            }
          }else if (this.config.numCols==2){
            if (!classRoom[s].hasOwnProperty('LastName') && !classRoom[s].hasOwnProperty('Surname')) throw undefined;
            if (!classRoom[s].hasOwnProperty('Name') && !classRoom[s].hasOwnProperty('FirstName')) throw undefined;
            let lastName=(typeof(classRoom[s].LastName)!=='undefined')?classRoom[s].LastName:(typeof(classRoom[s].Surname)!=='undefined')?classRoom[s].Surname:"";
            let name=(typeof(classRoom[s].Name)!=='undefined')?classRoom[s].Name:(typeof(classRoom[s].FirstName)!=='undefined')?classRoom[s].FirstName:"";
            
            result+='{"lastname":"'+lastName+'", "name":"'+name+'", "cod":"'+classRoom[s].Password+'"},';
          }
        }
        result=result.slice(0, -1);
        result+=']';
        return result;  
      }catch(e){
        this.excelData.messageFormatInfo="Error en el formato de estudiante, arregla el error en tu excel y luego actualiza la pagina.";
        throw e;        
      }
    },
    nameIsEmpty(item){      
      for (let key in item){
        if (key.slice(0,7)!='__EMPTY')
          return false;
      }
      return true;
    },
    getHeaderRow(sheet) {
      const headers = []
      const range = XLSX.utils.decode_range(sheet['!ref'])
      let C
      const R = range.s.r
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
        if (hdr.slice(0,7)!='UNKNOWN') headers.push(hdr)
      }
      return headers
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    }
  }
}
</script>

<style scoped>
.excel-upload-input{
  display: none;
  z-index: -9999;
}
.drop{
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
</style>