
<template>
  <v-container>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
      {{alertError}}
    </v-alert>
    <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
      {{alertSuccess}}
    </v-alert>
    <v-card outlined class="pa-5 mb-2">
        <v-row>
          <h1>Import Clever</h1>
        </v-row>
        <v-row>
            <v-col>                
                <v-row>
                    <v-col cols="5 my-lg-auto text-center">
                        <p class="text-h6 mb-1"><v-icon>mdi-account-supervisor-circle</v-icon>Country</p>
                    </v-col>
                    <v-col cols="7">
                        <v-select v-model="country" outlined :items="countries" item-text="name" menu-props="auto" label="Select country" hide-details return-object single-line @change="SelectState"></v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col cols="5 my-lg-auto text-center">
                        <p class="text-h6 mb-1"><v-icon>mdi-account-supervisor-circle</v-icon>State</p>
                    </v-col>
                    <v-col cols="7">
                        <v-select v-model="state" outlined :items="states" item-text="name" menu-props="auto" label="Select State" hide-details return-object single-line @change="SelectSchoolDistrict"></v-select>
                    </v-col>
                </v-row>
            </v-col>
             <v-col>
                <v-row>
                    <v-col cols="5 my-lg-auto text-center">
                        <p class="text-h6 mb-1"><v-icon>mdi-account-supervisor-circle</v-icon>School District</p>
                    </v-col>
                    <v-col cols="7">
                        <v-select v-model="schoolDistrict" outlined :items="schoolDistricts" item-text="name" menu-props="auto" label="Select School District" hide-details return-object single-line @change="SelectSchool"></v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col cols="5 my-lg-auto text-center">
                        <p class="text-h6 mb-1"><v-icon>mdi-account-supervisor-circle</v-icon>Schools</p>
                    </v-col>
                    <v-col cols="7">
                        <v-select v-model="school" outlined :items="schools" item-text="name" menu-props="auto" label="Select School District" hide-details return-object single-line @change="SelectSchoolImport"></v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
    <v-card outlined class="pa-5 mb-2">
        <v-row>
            <v-col cols="6">
             {{infoSchool}}
            </v-col>
            <v-col>
              <!-- <v-file-input label="File input" outlined dense @change="importf(this)"></v-file-input> -->
              <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
            </v-col>
            <v-col>
              <v-btn depressed @click="sendPreview">Guardar</v-btn>
            </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-img
              src="urlImgConfig" 
              height="200px"
              cover       
            ></v-img>
          </v-col>
          <v-col cols="6">
            <v-img
              src="urlImgConfig" 
              height="200px"
              cover       
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Descarga Formato de ejemplo: 
            <v-btn
              color="primary"
              width="20%"
              :href="urlFormat"
              :loading="loadingFormat"
              :disabled="loadingFormat"
              target="_blank"
              dowload
            >
              <v-icon>mdi-download</v-icon>
              Download
            </v-btn>
          </v-col>
        </v-row>
    </v-card>
    <v-card>
      <v-row>
        <v-col>
          <v-textarea
          id="txtRes"    
          filled
          label="Import results"
          ref="iResult"          
          readonly
          no-resize
          rows="10"
          v-model="importResult"
        ></v-textarea>
        </v-col>
      </v-row>
    </v-card>
    <div id="demo"></div>
    <v-dialog v-model="dialogLoad" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Wait please
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="preview" hide-overlay persistent width="700">
      <v-card color="white">
        <v-card-title class="justify-center pa-1 black--text">Preview document</v-card-title>
        <v-card-text>
          <template>
            <v-data-table
              :headers="headers"
              :items="tableData"
              :items-per-page="5"
              class="elevation-1"
            ></v-data-table>
          </template>
        </v-card-text>
        <v-card-actions>        
          <v-btn color="success" @click="sendData">Guardar<v-icon>mdi-check</v-icon></v-btn>
          <v-btn color="blue-grey lighten-2" outlined class="mr-2" @click="preview=false">Cancel<v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
  </v-container>
</template>
<script>
import { db, functions, auth } from '../../../plugins/firebase'
import firebase from "firebase";
import UploadExcelComponent from '../../../components/uploadExcelClever'
import XLSX from 'xlsx'
export default {
  components: { UploadExcelComponent },
  data () {
    return {
      txtResult: null,
      importResult: '',
      urlFormat: '',
      loadingFormat: true,
      preview: false,
      country: '',
      countries: [],
      state: '',
      states: [],
      schoolDistrict: '',
      schoolDistricts: [],
      school: '',
      schools: [],
      schoolSelected: {},
      dialogLoad: false,
      table: 'schoolAdmin',
      alertError: '',
      alertSuccess: '',
      infoSchool: '',
      tableData:{},
      teacher: {
        email:"sanphillips@leelee.com",
        password: "sanphillips",
        name: "Victor",
        lastName: "Alvarado",
        className : "1A",
        classType: "1st Grade",
        classroom:[

        {lastname:'Cruz', name:'Jacob', cod:'4232548'},
        {lastname:'Hernandez', name:'Joel', cod:'5015923'},
        // { lastname:'Iliany', name:'Velasquez', cod:'5165013'}, --Pre-School--Kindergarten--1st Grade--2nd Grade--3rd Grade
        ]
      },
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Email', value: 'email' }
      ]
    }
  },
  created () {
    this.ListCountries();
    this.resourceFormat();
    // const setAdmin = functions.httpsCallable('crearUsuarioUidPersonalizado')
    // setAdmin();
    // setAdmin({role: 'student'}).then((doc) =>{
    //   console.log(doc);
    // });
  },
  methods: {
    async ListCountries () {
      await db.collection('SchoolsData').doc('RegionSchoolD').get().then((doc) => {

        let Countries = doc.data().Countries;
        let ids = Object.keys(Countries);
        let newCountries = [];
        ids.forEach(element => {
          newCountries.push({ id: element, name: Countries[element] })
        });
        this.countries = newCountries;
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectState () {
      let StateReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id);
      await StateReference.get().then((doc) => {
        let ListStates = doc.data().States;
        let ids = Object.keys(ListStates);
        let newStates = [];
        ids.forEach(element => {
          newStates.push({ id: element, name: ListStates[element] })
        });
        this.states = newStates;
        // aqui reiniciamos los demas selects
        this.SchoolDistrict = [];
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectSchoolDistrict () {
      let SchoolDistrictReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id);
      await SchoolDistrictReference.get().then((doc) => {
        // console.log(doc.data());
        let ListSchoolDistrict = doc.data().SchoolDistricts;
        let ids = Object.keys(ListSchoolDistrict);
        let newSchoolDistricts = [];
        ids.forEach(element => {
          newSchoolDistricts.push({ id: element, name: ListSchoolDistrict[element] })
        });
        this.schoolDistricts = newSchoolDistricts;
        // aqui reiniciamos los demas selects

        //this.schools = [];
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectSchool(){
        let SchoolReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').doc(this.schoolDistrict.id);
      await SchoolReference.get().then((doc) => {
        // console.log(doc.data());
        let ListSchool = doc.data().Schools;
        let ids = Object.keys(ListSchool);
        let newSchools = [];
        ids.forEach(element => {
          newSchools.push({ id: element, name: ListSchool[element] })
        });
        this.schools = newSchools;
        // aqui reiniciamos los demas selects

        //this.schools = [];
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectSchoolImport() {
      let DataSchool = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').doc(this.schoolDistrict.id).collection('Schools').doc(this.school.id);
      await DataSchool.get().then((doc) => {
        // console.log(doc.data());
        //let infoschool = doc.data().Schools;
        this.infoSchool = doc.data().SchoolName + ' , students licences assigned : ' + doc.data().TeacherLicencesAssigned + ' , teacher licences  assigned: ' + doc.data().StudentLicencesAssigned;
        this.schoolSelected = doc.data()
      }).catch((error) => {
        console.log(error)
      });
        // console.log("presionarlo");
        // console.log(this.school);
    },
    beforeUpload(file) {
      console.log(file);
      console.log(file.name);
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.$message({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning'
      })
      return false
    },
    handleSuccess({ dataStructure, header, messageFormatInfo }) {
      this.tableData = dataStructure;
      this.tableHeader = header;
      console.log("Aqui");
      console.log(dataStructure);
      console.log("header");
      console.log(header);
      console.log("messageFormatInfo", dataStructure.length);
      console.log(messageFormatInfo);
    },
    sendPreview(){
      if (this.school.name==undefined){
        this.alertError = 'No tienes ninguna escuela seleccionada.';
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
        return;
      }

      if (this.tableData.length==undefined || this.tableData.length==0){
        this.alertError = 'No tienes nada que guardar.';
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
        return;
      }

      this.preview=true;
    },
    async sendData(){
      this.preview=false;
      document.scrollTop=document.scrollHeight;
      this.$refs.iResult.focus();
      this.txtResult=document.getElementById('txtRes');
      //this.txtResult.focus();
      
      if (this.school.name==undefined){
        this.alertError = 'No tienes ninguna escuela seleccionada.';
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
        return;
      }

      if (this.tableData.length==undefined || this.tableData.length==0){
        this.alertError = 'No tienes nada que guardar.';
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
        return;
      }
      
      for (let t=0; t<this.tableData.length; t++){        
        this.registerTeacher(this.tableData[t]).then(response => {
          console.log(response);
          let info="Profesor: "+this.tableData[t].name+" "+this.tableData[t].lastName+", con Email: "+this.tableData[t].email;
          if (!response.result) {              
            this.importResult+=info+", No fue registrado, Error: "+response.message+"   \n";
          } else {
            this.importResult+=info+", fue registrado. \n";
            this.txtResult.scrollTop=this.txtResult.scrollHeight;           
          }            
        });    
      }
    },    
    async registerTeacher(teacher){
      let answer = { result: '', message: '' };
      try {
        let referenceTeacher = db.doc('SchoolsData/RegionSchoolD/Countries/' + this.country.id + '/States/' + this.state.id + '/SchoolDistricts/' + this.schoolDistrict.id + '/Schools/' + this.school.id)
        let email = teacher.email;
        let existe = await this.existsUser(email);
        console.log(existe);
        // return;
        if (existe) {
          answer.result = false;
          answer.message = 'this email already exists';
          return answer;
        }

        let ReferenceGrades = db.doc('SchoolsData/RegionSchoolD/Countries/' + this.country.id + '/GradeData/GradeD');
        console.log('antes de batch');
        let emailSplit = email.replace(".", "_--_");        
        
        var batch = db.batch();
        batch.update(referenceTeacher, { [`PendingUsers.${[emailSplit]}`]: { Email: teacher.email, Name: teacher.name, LastName: teacher.lastName }, AvailableTeacherLicences: firebase.firestore.FieldValue.increment(-1) });
        batch.set(db.collection("PreUsersClever").doc(email), { Reference: referenceTeacher, ListGradesReference: ReferenceGrades, School: this.school.name, LicenseExpirationDate: this.schoolSelected.DateExpired });
        await batch.commit();
        console.log('despues de batch');      
        answer.result = true;
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log(error);
      }
      return answer;
    },
    async existsUser (email) {
      let signInMethods = await auth().fetchSignInMethodsForEmail(email);
      if (signInMethods.length == 0) {
        let docVerify = await db.collection("PreUsersClever").doc(email).get();
        if (!docVerify.exists) {
          console.log('No existe!');
          return false;
        } else {
          console.log('si existe');
          return true
        }
              
      } else {
        console.log(signInMethods)
        console.log('si existe')
        return true;
      }
    },
    resourceFormat() {
      let aux = this;
      var storage = firebase.storage();      
      storage
        .ref("Resources/School Maestros Clever.xlsx")
        .getDownloadURL()
        .then(function (url) {
          aux.loadingFormat = false;
          aux.urlFormat = url;
        });
    }
  },
  computed: {
    formTitle () {
      return this.editedUID === -1 ? 'New School District' : 'Edit School District'
    },
  },
  watch: {
    dialogSchoolDistrict (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogLoad (val) {
      console.log(val)
      // if (!val) return
      // console.log(this.$nuxt.isOffline);
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },
  }
}
</script>
